import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { useState } from "react";

interface HoverDataProp {
  children: React.ReactNode;
}

export default function HoverData({ children }: HoverDataProp) {
  const [visible, setVisible] = useState(false);

  return (
    <div onMouseLeave={() => setVisible(false)}>
      <Tooltip arrow title={children} open={visible}>
        <span
          onMouseEnter={() => setVisible(true)}
          onClick={() => {
            setVisible(!visible);
          }}
          style={{ cursor: "pointer", marginLeft: "5px" }}
        >
          <InfoOutlinedIcon />
        </span>
      </Tooltip>
    </div>
  );
}
