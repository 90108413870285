import { createSlice } from "@reduxjs/toolkit";
import {
  CardAccessType,
  CardStatus,
  OrganizationType,
  User,
  subscriptionCategory,
  subscriptionStandard
} from "src/types/commonTypes";
import { Wallet } from "./wallets/walletSlice";
import userColumns from "src/components/userList/listTable/userListTable";
import walletColumns from "src/components/walletList/walletListTable/WalletListTable";
import transactioncolumns from "src/components/transactionList/transTable/TransTable";
import cardColumns from "src/page/cards/cardsConfig";
import organisationColumns from "src/components/orgListCard/listTable/orgListTable";
import internalUserColumns from "src/components/InternalUserList/listTable/internalUserListTable";
import internalWalletColumns from "src/components/internalWalletList/internalwalletListTable/InternalWalletListTable";
import internalCardColumns from "src/page/internalcards/internalcardsConfig";
import internalOrganisationCoulmns from "src/components/internalOrgListCard/listTable/internalOrgListTable";
import internalFileColumns from "src/page/internalFile/internalFileConfig";
import cardSettingColumns from "src/page/cardSetting/cardSettingConfig";
import plansColumns from "src/page/plans/plansConfig";
import chlidrenPlansColumn from "src/page/childrenPlans/childrenPlansConfig";
import reportsColumn from "src/page/reports/reportsConfig";
import reportsContentColumn from "src/page/reportsContent/reportsContentConfig";
import { Card } from "./cards/cardSlice";

const initialTransactionFilters: {
  wallet: Wallet[] | null;
  user: User[] | null;
  startDate: string | null;
  endDate: string | null;
  minAmount: string | null;
  maxAmount: string | null;
  activityName: string | null;
} = {
  wallet: null,
  user: null,
  startDate: null,
  endDate: null,
  minAmount: null,
  maxAmount: null,
  activityName: null
};

const initialWalletFilters: {
  startDate: string | null;
  endDate: string | null;
  minAmount: string | null;
  maxAmount: string | null;
  status: string | null;
  type: string[] | null;
  user: User[] | null;
} = {
  startDate: null,
  endDate: null,
  minAmount: null,
  maxAmount: null,
  status: "ACTIVE",
  type: ["GENERAL", "CASH"],
  user: null
};

const initialCardFilters: {
  status: CardStatus | null;
  type: CardAccessType | null;
  wallet: Wallet[] | null;
  user: User[] | null;
} = {
  status: null,
  type: null,
  wallet: null,
  user: null
};

const initialNovattiFilters: {
  startDate: string | null;
  endDate: string | null;
} = {
  startDate: null,
  endDate: null
};
const initialReportsFilters: {
  startDate: string | null;
  endDate: string | null;
} = {
  startDate: null,
  endDate: null
};

const initialBulkUploadFilters: {
  fileType: string[] | null;
} = {
  fileType: null
};

const initialCardSettingFilters: {
  status: string | null;
  wallet: Wallet | null;
  card: Card | null;
} = {
  status: null,
  wallet: null,
  card: null
};
const initialPlansFilters: {
  status: string | null;
  category: subscriptionCategory | null;
  type: OrganizationType | null;
  standard: subscriptionStandard | null;
} = {
  status: "ACTIVE",
  category: null,
  type: null,
  standard: null
};

const initialColumn: {
  transaction: string[];
  wallet: string[];
  user: string[];
  card: string[];
  organisation: string[];
  internalUser: string[];
  internalWallet: string[];
  internalCard: string[];
  internalOrganisation: string[];
  internalFile: string[];
  internalFileContent: string[];
  cardSetting: string[];
  plans: string[];
  childrenPlans: string[];
  reports: string[];
  reportsContent: string[];
} = {
  transaction: transactioncolumns.map((col) => col.key),
  wallet: walletColumns().map((col) => col.key),
  user: userColumns().map((col) => col.key),
  card: cardColumns.map((col) => col.key),
  cardSetting: cardSettingColumns.map((col) => col.key),
  organisation: organisationColumns.map((col) => col.key),
  internalUser: internalUserColumns().map((col) => col.key),
  internalWallet: internalWalletColumns().map((col) => col.key),
  internalCard: internalCardColumns.map((col) => col.key),
  internalOrganisation: internalOrganisationCoulmns.map((col) => col.key),
  internalFile: internalFileColumns.map((col) => col.key),
  internalFileContent: [],
  plans: plansColumns.map((col) => col.key),
  childrenPlans: chlidrenPlansColumn.map((col) => col.key),
  reports: reportsColumn.map((col) => col.key),
  reportsContent: reportsContentColumn.map((col) => col.key)
};

const initialState = {
  transaction: initialTransactionFilters,
  wallet: initialWalletFilters,
  card: initialCardFilters,
  novatti: initialNovattiFilters,
  bulkUpload: initialBulkUploadFilters,
  cardSetting: initialCardSettingFilters,
  plans: initialPlansFilters,
  reports: initialReportsFilters,
  column: initialColumn
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFilters: () => {
      return { ...initialState };
    },
    setTransactionFilter: (state, action) => {
      state.transaction = { ...state.transaction, ...action.payload };
    },
    resetTransactionFilters: (state) => {
      state.transaction = initialTransactionFilters;
    },
    setWalletFilters: (state, action) => {
      state.wallet = { ...state.wallet, ...action.payload };
    },
    resetWalletFilters: (state) => {
      state.wallet = initialWalletFilters;
    },
    setCardFilters: (state, action) => {
      state.card = { ...state.card, ...action.payload };
    },
    resetCardFilters: (state) => {
      state.card = initialCardFilters;
    },
    setNovattiFilter: (state, action) => {
      state.novatti = { ...state.novatti, ...action.payload };
    },
    setReportsFilter: (state, action) => {
      state.reports = { ...state.reports, ...action.payload };
    },
    setBulkUploadFilter: (state, action) => {
      state.bulkUpload = { ...state.bulkUpload, ...action.payload };
    },
    resetNovattiFilters: (state) => {
      state.novatti = initialNovattiFilters;
    },
    resetReportsFilters: (state) => {
      state.novatti = initialNovattiFilters;
    },
    setCardSettingFilters: (state, action) => {
      state.cardSetting = { ...state.cardSetting, ...action.payload };
    },
    resetCardSettingFilters: (state) => {
      state.cardSetting = initialCardSettingFilters;
    },
    setPlansFilters: (state, action) => {
      state.plans = { ...state.plans, ...action.payload };
    },
    resetPlansFilters: (state) => {
      state.plans = initialPlansFilters;
    },
    setColumnFilter: (state, action) => {
      state.column = {
        ...state.column,
        ...action.payload
      };
    }
  }
});

export const {
  setTransactionFilter,
  resetFilters,
  resetTransactionFilters,
  setWalletFilters,
  resetWalletFilters,
  setCardFilters,
  resetCardFilters,
  setNovattiFilter,
  resetNovattiFilters,
  setBulkUploadFilter,
  setCardSettingFilters,
  resetCardSettingFilters,
  setPlansFilters,
  resetPlansFilters,
  setColumnFilter,
  setReportsFilter,
  resetReportsFilters
} = filterSlice.actions;

export default filterSlice.reducer;
