import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./internalFIle.module.scss";
import Table from "rc-table";
import { Button, TablePagination } from "@mui/material";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchFilesAction } from "./internalIFileSlice";
import columns from "./internalFileConfig";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NOVATTI_LIMIT, NOVATTI_PAGE } from "src/components/constant/queryConstants";
import Modalui from "src/components/UI/modal/Modalui";
import { FilterTagType, generateFilterTags } from "src/components/UI/filterTag/filterTagUtils";
import FilterTag from "src/components/UI/filterTag/FilterTag";
import { setColumnFilter, setNovattiFilter } from "../filtersSlice";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
const NovattiFilter = lazy(() => import("src/components/novattiFilterModal/NovattiFilterModal"));

export default function InternalFile() {
  const dispatch = useAppDispatch();
  const { internalfiles, loading } = useAppSelector((state) => state.internalfile);
  const { novatti: novattiFilters, column: columnFilter } = useAppSelector((state) => state.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilterCard, setOpenFilterCard] = useState<boolean>(false);
  const limit = searchParams.get(NOVATTI_LIMIT) ?? "10";
  const page = searchParams.get(NOVATTI_PAGE) ?? "0";

  const fetchNovattiTransaction = useCallback(() => {
    const filter: any = {};
    if (novattiFilters.startDate) {
      filter.date = { ...(filter.date || {}), min: novattiFilters.startDate };
    }
    if (novattiFilters.endDate) {
      filter.date = {
        ...(filter.date || {}),
        max: novattiFilters.endDate
      };
    }
    const data = {
      size: Number(limit),
      from: Number(limit) * Number(page),
      date: filter.date,
      fileTypes: ["NOVATTI_DAILY_TRANSACTION"]
    };

    dispatch(fetchFilesAction(data));
  }, [dispatch, page, limit, novattiFilters]);

  useEffect(() => {
    fetchNovattiTransaction();
  }, [fetchNovattiTransaction]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(NOVATTI_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(NOVATTI_LIMIT, event.target.value as string);
    searchParams.delete(NOVATTI_PAGE);
    setSearchParams(searchParams);
  };

  const removeTransactionFilter = (filterName: string) => {
    let newFilters;

    if (filterName === "date") {
      newFilters = {
        ...novattiFilters,
        startDate: null,
        endDate: null
      };
    } else {
      newFilters = { ...novattiFilters, [filterName]: null };
    }

    dispatch(setNovattiFilter(newFilters));
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.internalFile?.includes(col.key));
  }, [columnFilter?.internalFile]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        internalFile: selectedColumns
      })
    );
  };

  return (
    <>
      <Modalui open={openFilterCard} handleClose={() => setOpenFilterCard(false)} modaluiOver={styles.filterModalOver}>
        <Suspense fallback={<CircularLoader />}>
          <NovattiFilter handleClose={() => setOpenFilterCard(false)} />
        </Suspense>
      </Modalui>
      <div className={styles.fileWrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard heading={"View Your Files"} subHeading={"View details of all the files"} />
        </div>
        <div className={styles.fileList}>
          <div className={styles.fileListHead}>
            <div className={styles.fileListHeadIn}>
              <div className={styles.fileListHeadD}>
                <h3>Files list</h3>
                <p>Manage all the files</p>
              </div>
              <div className={styles.fileListHeadBtn}>
                <Button variant="outlined" className={styles.clearFilter} onClick={() => setOpenFilterCard(true)}>
                  Filter Novatti
                </Button>
              </div>
            </div>
            <div className={styles.cardListFilter}>
              <div className={styles.filterTagsContainer}>
                {generateFilterTags(novattiFilters).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeTransactionFilter(filterTag.key)}
                  />
                ))}
              </div>
              <TableColumnFilter
                columns={columns}
                selectedColumn={columnFilter?.internalFile}
                mandatoryColumns={["assetId", "operation"]}
                onApply={handleColumnSelection}
                iconContanerClass={styles.tableColumnContainer}
              />
            </div>
          </div>
          <div className={styles.fileListTable}>
            <Table
              columns={filteredColumns}
              data={loading ? [] : internalfiles.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={internalfiles.size ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(limit)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
