import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./orderCardModal.module.scss";
import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { orderCardAction } from "src/page/cards/cardSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CardAccessType } from "src/types/commonTypes";
import { UserDetail } from "src/page/userDetail/userDetailSlice";
import { PRIVATE } from "src/components/constant/constant";

interface Iprops {
  onSuccess: () => void;
  rootContainer?: string;
  heading?: string;
  buttonText?: string;
  userData?: UserDetail;
}

export default function OrderCardModal({
  onSuccess,
  rootContainer,
  heading = "Order Card",
  buttonText,
  userData
}: Iprops) {
  const { orderCardLoading } = useAppSelector((Rstate) => Rstate.cards);
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState(userData?.user?.firstName || "");
  const [lastName, setLastName] = useState(userData?.user?.lastName || "");
  const [line1, setLine1] = useState(userData?.user?.line1 || "");
  const [line2, setLine2] = useState(userData?.user?.line2 || "");
  const [city, setCity] = useState(userData?.user?.city || "");
  const [state, setState] = useState(userData?.user?.state || "");
  const [postCode, setPostCode] = useState(userData?.user?.zipcode || "");
  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postcode: ""
  });

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOrderClick = () => {
    setConfirmOpen(true);
  };

  const handleConfirmOrder = () => {
    setConfirmOpen(false);
    handleAddAndStay();
  };

  const handleAddAndStay = () => {
    if (!firstName.trim() || !lastName.trim() || !line1.trim() || !city.trim() || !state.trim() || !postCode.trim()) {
      setError({
        ...error,
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        address: line1 ? "" : "Address is required",
        city: city ? "" : "City is required",
        state: state ? "" : "State is required",
        postcode: postCode ? "" : "Postcode is required"
      });
      return;
    }
    const input = {
      user_id: userData?.user?.id + "",
      card_type: PRIVATE as CardAccessType,
      first_name: firstName,
      last_name: lastName,
      organisation_id: String(cognitoConfig?.currentOrganisation?.organisation_id),
      address: {
        line_1: line1,
        line_2: line2,
        city: city,
        post_code: postCode
      }
    };

    dispatch(
      orderCardAction(input, () => {
        onSuccess();
      })
    );
  };

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.user?.firstName || "");
      setLastName(userData?.user?.lastName || "");
      setLine1(userData?.user?.line1 || "");
      setLine2(userData?.user?.line2 || "");
      setCity(userData?.user?.city || "");
      setState(userData?.user?.state || "");
      setPostCode(userData?.user?.zipcode || "");

      setError({
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        postcode: ""
      });
    }
  }, [userData]);

  return (
    <div className={`${styles.referModalBox} ${rootContainer}`}>
      {!confirmOpen ? (
        <>
          <main className={styles.referModalTop}>
            <form>
              <div className={styles.close}>
                <h5>{heading}</h5>
              </div>
              {orderCardLoading ? (
                <div className={styles.progress}>
                  <CircularProgress sx={{ color: "#0f172a" }} />
                </div>
              ) : (
                <div className={styles.formfill}>
                  <div className={styles.name}>
                    <div className={styles.Fname}>
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {error.firstName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.firstName}
                        </div>
                      )}
                    </div>
                    <div className={styles.Lname}>
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {error.lastName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.addressrowI}>
                    <div className={styles.address}>
                      <label>Line 1</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={line1}
                        onChange={(e) => setLine1(e.target.value)}
                      />
                      {error.address && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.address}
                        </div>
                      )}
                    </div>
                    <div className={styles.address}>
                      <label>Line 2</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={line2}
                        onChange={(e) => setLine2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.addressrowII}>
                    <div className={styles.city}>
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {error.city && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.city}
                        </div>
                      )}
                    </div>
                    <div className={styles.state}>
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                      {error.state && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.state}
                        </div>
                      )}
                    </div>
                    <div className={styles.postCode}>
                      <label>Postcode</label>
                      <input
                        type="number"
                        placeholder="Enter Text Here"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                      {error.postcode && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </main>
          <footer className={styles.referModalBottom}>
            <div className={styles.referModalBtn}>
              <Stack spacing={2} direction="row">
                <Button
                  className={styles.sendBtn}
                  variant="contained"
                  onClick={handleOrderClick}
                  disabled={orderCardLoading}
                >
                  {orderCardLoading ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    buttonText || "Order Card"
                  )}
                </Button>
              </Stack>
            </div>
          </footer>
        </>
      ) : (
        <>
          <main className={styles.referModalTop}>
            <div className={styles.close}>
              <h5>
                Confirm Card <span className={styles.highlight}>Order</span>
              </h5>
            </div>
            <p className={styles.description}>
              Please read the details of your address carefully. If you are in an office building, ensure your business
              name is correctly identified.
            </p>
            <p className={styles.infoText}>Your first card is free, but replacements have a $12 fee.</p>
            <div className={styles.addressBox}>
              <p>
                <strong>Name on Envelope:</strong> {userData?.user?.firstName || "N/A"}{" "}
                {userData?.user?.lastName || "N/A"}
              </p>
              <p>
                <strong>Address 1:</strong> {userData?.user?.line1 || "N/A"}
              </p>
              <p>
                <strong>Address 2:</strong> {userData?.user?.line2 || "N/A"}
              </p>
              <p>
                <strong>Suburb:</strong> {userData?.user?.city || "N/A"}
              </p>
              <p>
                <strong>State:</strong> {userData?.user?.state || "N/A"}
              </p>
              <p>
                <strong>Postcode:</strong> {userData?.user?.zipcode || "N/A"}
              </p>
            </div>
          </main>
          <footer className={styles.referModalBottom}>
            <Stack spacing={2} direction="row" className={styles.buttonContainer}>
              <Button onClick={() => setConfirmOpen(false)} variant="contained" className={styles.changeAddressButton}>
                Change Address
              </Button>
              <Button onClick={handleConfirmOrder} variant="contained" className={styles.confirmButton}>
                Confirm Order
              </Button>
            </Stack>
          </footer>
        </>
      )}
    </div>
  );
}
