import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { getReport, ReportsRequest } from "src/network/graphql/analyticsService";

export type Reports = {
  report_id: string;
  report_type: string;
  status: string;
  report_date: string;
};

const initialReports: Reports[] = [];

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    error: null,
    reports: {
      list: initialReports,
      size: 0
    }
  },
  reducers: {
    fetchReportsStart: (state) => {
      state.loading = true;
    },
    fetchReportsSuccess: (state, action) => {
      state.loading = false;
      state.reports = action.payload;
    },
    fetchReportsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { fetchReportsStart, fetchReportsSuccess, fetchReportsFail } = reportsSlice.actions;

export const fetchReportsAction = (data: ReportsRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchReportsStart());
    try {
      const response = await getReport(data);

      dispatch(
        fetchReportsSuccess({
          list: response.data.search_report.reports,
          size: response.data.search_report.total_size
        })
      );
    } catch (error) {
      dispatch(fetchReportsFail(error));
    }
  };
};

export default reportsSlice.reducer;
