import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { getReportContent, ReportsContentRequest } from "src/network/graphql/analyticsService";

export type ReportsContent = {
  report_id: string;
  report_content_id: string;
  report_type: string;
  object_id: string;
  object_type: string;
  data: object;
};

const initialReportContent: ReportsContent[] = [];

const reportContentSlice = createSlice({
  name: "reportsContent",
  initialState: {
    loading: false,
    error: null,
    reportsContent: {
      list: initialReportContent,
      size: 0
    }
  },
  reducers: {
    fetchFilesContentStart: (state) => {
      state.loading = true;
    },
    fetchFilesContentSuccess: (state, action) => {
      state.loading = false;
      state.reportsContent = action.payload;
    },
    fetchFilesContentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { fetchFilesContentStart, fetchFilesContentSuccess, fetchFilesContentFail } = reportContentSlice.actions;

export const fetchReportsContentAction = (data: ReportsContentRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchFilesContentStart());
    try {
      const response = await getReportContent(data);
      const structuredResponse = response.data.search_report_content.report_content_list.map((data: any) => ({
        object_id: data.object_id,
        object_type: data.object_type,
        data: JSON.parse(data.data)
      }));

      dispatch(
        fetchFilesContentSuccess({
          list: structuredResponse,
          size: response.data.search_report_content.total_size
        })
      );
    } catch (error) {
      dispatch(fetchFilesContentFail(error));
    }
  };
};

export default reportContentSlice.reducer;
