import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "src/page/auth/loginSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import dashboardReducer from "./dashboard/dashboardSlice";
import userReducer from "./users/usersSlice";
import walletReducer from "./wallets/walletSlice";
import userDetailReducer from "src/page/userDetail/userDetailSlice";
import walletDetailReducer from "src/page/walletDetail/walletDetailSlice";
import transactionReducer from "src/page/transactions/transactionSlice";
import cardReducer from "src/page/cards/cardSlice";
import organisationReducer from "src/page/organisation/organisationSlice";
import organisationDetailReducer from "src/page/organisationDetail/organisationDetailSlice";
import superledgerReducer from "src/page/superLedger/superLedgerSlice";
import filterReducer from "src/page/filtersSlice";
import newPaymentReducer from "src/page/newPayment/newPaymentSlice";
import budgetReducer from "src/page/budget/budgetSlice";
import changePinReducer from "src/page/changePin/changePinSlice";
import homeReducer from "src/page/home/homeSlice";
import depositReducer from "src/page/deposit/depositSlice";
import activityReducer from "src/page/activity/activitySlice";
import ledgerReducer from "src/page/ledger/ledgerSlice";
import onBoardingReducer from "src/page/onBoarding/onBoardingSlice";
import internalDashboardReducer from "src/page/internalDashboard/InternalDashboardSlice";
import internalUserReducer from "src/page/internalUser/internalUserSlice";
import internalWalletReducer from "src/page/internalWallets/internalWalletSlice";
import internalfileReducer from "src/page/internalFile/internalIFileSlice";
import internalCardsReducer from "src/page/internalcards/internalcardSlice";
import bulkOrderReducer from "src/page/bulkUpload/bulkUploadSlice";
import internalfileContentReducer from "src/page/internalFileContent/internalIFileContentSlice";
import cardSettingReducer from "src/page/cardSetting/cardSettingSlice";
import internalTransactionReducer from "src/page/internalTransactions/InternalTransactionSlice";
import plansReducer from "src/page/plans/plansSlice";
import childrenPlansReducer from "src/page/childrenPlans/childrenPlansSlice";
import mccReducer from "src/page/mcc/mccSlice";
import referralProgramReducer from "src/page/referralProgram/referralProgramSlice";
import uploadFilesReducer from "src/page/uploadFiles/uploadFilesSlice";
import reportsReducer from "src/page/reports/reportsSlice";
import reportsContentReducer from "src/page/reportsContent/reportsContentSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"]
};

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  onBoarding: onBoardingReducer,
  user: userReducer,
  wallet: walletReducer,
  userDetail: userDetailReducer,
  walletDetail: walletDetailReducer,
  transaction: transactionReducer,
  cards: cardReducer,
  organisation: organisationReducer,
  organisationDetail: organisationDetailReducer,
  superledger: superledgerReducer,
  newPayment: newPaymentReducer,
  budget: budgetReducer,
  changePin: changePinReducer,
  home: homeReducer,
  deposit: depositReducer,
  activity: activityReducer,
  filters: filterReducer,
  ledger: ledgerReducer,
  internalDashboard: internalDashboardReducer,
  internalUser: internalUserReducer,
  internalWallet: internalWalletReducer,
  internalCard: internalCardsReducer,
  internalfile: internalfileReducer,
  bulkOrder: bulkOrderReducer,
  internalFileContent: internalfileContentReducer,
  cardSetting: cardSettingReducer,
  internalTransaction: internalTransactionReducer,
  plans: plansReducer,
  childrenPlans: childrenPlansReducer,
  mcc: mccReducer,
  referralProgram: referralProgramReducer,
  uploadFiles: uploadFilesReducer,
  reports: reportsReducer,
  reportsContent: reportsContentReducer
});

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer)
});

export type StoreDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
export const useAppDispatch: () => StoreDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
