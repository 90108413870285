import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./internalFIleContent.module.scss";
import Table from "rc-table";
import { FormControl, InputLabel, MenuItem, Select, TablePagination } from "@mui/material";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchFilesContentAction, fetchFilesDataAction, setReloadFileContent } from "./internalIFileContentSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { NOVATTI_LIMIT, NOVATTI_PAGE } from "src/components/constant/queryConstants";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
import { setColumnFilter } from "../filtersSlice";
import { getColumnsForFileType } from "./internalFileContentConfig";
import { NOVATTI_DAILY_TRANSACTION } from "src/components/constant/constant";

export default function InternalFileContent() {
  const dispatch = useAppDispatch();
  const { fileId, fileType } = useParams();
  const { internalfilesContent, loading, internalfilesdetail, reloadFileContent } = useAppSelector(
    (state) => state.internalFileContent
  );
  const { column: columnFilter } = useAppSelector((state) => state.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [processStatus, setProcessStatus] = useState<string | null>();
  const [settlementStatus, setSettlementStatus] = useState<string | null>();
  const limit = searchParams.get(NOVATTI_LIMIT) ?? "10";
  const page = searchParams.get(NOVATTI_PAGE) ?? "0";

  const fetchNovattiTransaction = useCallback(() => {
    if (fileId) {
      const filters: any = {
        file_ids: [fileId]
      };
      if (settlementStatus) {
        filters.settlement_status = [settlementStatus];
      }
      if (processStatus) {
        filters.process_status = [processStatus];
      }
      const data = {
        size: Number(limit),
        from: Number(limit) * Number(page),
        filterContentFilter: filters
      };
      const detailData = {
        size: 5,
        from: 0,
        fileIds: [fileId]
      };

      dispatch(fetchFilesContentAction(data));
      dispatch(fetchFilesDataAction(detailData));
    }
  }, [dispatch, page, limit, settlementStatus, processStatus]);

  useEffect(() => {
    fetchNovattiTransaction();
  }, [fetchNovattiTransaction]);

  useEffect(() => {
    if (reloadFileContent) {
      fetchNovattiTransaction();
      setReloadFileContent(false);
    }
  }, [reloadFileContent]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(NOVATTI_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(NOVATTI_LIMIT, event.target.value as string);
    searchParams.delete(NOVATTI_PAGE);
    setSearchParams(searchParams);
  };

  const columns = useMemo(() => {
    return getColumnsForFileType(fileType);
  }, [fileType]);

  const filteredColumns = useMemo(() => {
    return columns.filter((col: any) => columnFilter?.internalFileContent?.includes(col.key));
  }, [columns, columnFilter?.internalFileContent]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(setColumnFilter({ internalFileContent: selectedColumns }));
  };

  useEffect(() => {
    if (!columnFilter?.internalFileContent?.length) {
      dispatch(setColumnFilter({ internalFileContent: columns.map((col: any) => col.key) }));
    }
  }, [dispatch, columnFilter?.internalFileContent, columns]);

  return (
    <>
      <div className={styles.fileWrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard heading={"View Your Files Content"} subHeading={"View details of uploaded files"} />
        </div>
        <div className={styles.fileList}>
          <div className={styles.fileListHead}>
            <div className={styles.fileListHeadIn}>
              <div className={styles.fileListHeadD}>
                <h3>{`${internalfilesdetail?.list[0]?.fileName ?? internalfilesdetail?.list[0]?.bucketKey.split("/").pop()} | ${internalfilesdetail?.list[0]?.date}`}</h3>
                <p>Manage all the files content</p>
              </div>
            </div>
            <div className={styles.fileListFilter}>
              <div className={styles.selectFilter}>
                <FormControl sx={{ minWidth: 180 }}>
                  <InputLabel id="process-status-label">Process Status</InputLabel>
                  <Select
                    value={processStatus}
                    onChange={(e) => {
                      setProcessStatus(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    labelId="process-status-label"
                    label="Process Status"
                    sx={{
                      color: {
                        COMPLETED: "",
                        FAILED: "",
                        CREATED: "",
                        "": "grey",
                        default: "grey"
                      }[processStatus ?? "default"]
                    }}
                  >
                    <MenuItem value="" sx={{ color: "grey" }}>
                      None
                    </MenuItem>
                    <MenuItem value="COMPLETED">Completed</MenuItem>
                    <MenuItem value="FAILED">Failed</MenuItem>
                    <MenuItem value="CREATED">Created</MenuItem>
                  </Select>
                </FormControl>
                {fileType === NOVATTI_DAILY_TRANSACTION && (
                  <FormControl sx={{ minWidth: 180 }}>
                    <InputLabel id="settlement-status-label">Settlement Status</InputLabel>
                    <Select
                      value={settlementStatus}
                      onChange={(e) => {
                        setSettlementStatus(e.target.value);
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      labelId="settlement-status-label"
                      label="Settlement Status"
                      sx={{
                        color: {
                          MATCHED: "",
                          MISMATCHED: "",
                          NOVATTI_ONLY: "",
                          "": "grey",
                          default: "grey"
                        }[settlementStatus ?? "default"]
                      }}
                    >
                      <MenuItem value="" sx={{ color: "grey" }}>
                        None
                      </MenuItem>
                      <MenuItem value="MATCHED">Matched</MenuItem>
                      <MenuItem value="SETTLED">Settled</MenuItem>
                      <MenuItem value="MISMATCHED">Mismatched</MenuItem>
                      <MenuItem value="PARTIAL_MATCHED">Partial Match</MenuItem>
                      <MenuItem value="NOVATTI_ONLY">Novatti Only</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>
              <TableColumnFilter
                columns={columns}
                selectedColumn={columnFilter?.internalFileContent}
                mandatoryColumns={["contentId", "data", "errMsg"]}
                onApply={handleColumnSelection}
                iconContanerClass={styles.tableColumnContainer}
              />
            </div>
          </div>
          <div className={styles.fileListTable}>
            <Table
              columns={filteredColumns}
              data={loading ? [] : internalfilesContent.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={internalfilesContent.size ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(limit)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
