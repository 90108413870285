import { createBrowserRouter } from "react-router-dom";
import Users from "src/page/users/Users";
import Wallet from "src/page/wallets/Wallets";
import Transactions from "src/page/transactions/Transactions";
import UserDetail from "src/page/userDetail/UserDetail";
import WalletDetail from "src/page/walletDetail/WalletDetail";
import Dashboard from "src/page/dashboard/Dashboard";
import Login from "./auth/auth";
import { lazy } from "react";
import { useAppSelector } from "./store";
import Cards from "./cards/cards";
import Organisation from "./organisation/Organisation";
import OrganisationDetail from "./organisationDetail/OrganisationDetail";
import SuperLedgers from "./superLedger/SuperLedgers";
import OnBoarding from "./onBoarding/OnBoarding";
import Home from "./home/Home";
import Activity from "./activity/Activity";
import Budget from "./budget/Budget";
import NewPayment from "./newPayment/NewPayment";
import ChangePin from "./changePin/ChangePin";
import Deposit from "./deposit/Deposit";
import Ledgers from "./ledger/Ledger";
import InternalPayment from "src/components/newPaymentCards/InternalPay";
import InternalUsers from "./internalUser/InternalUsers";
import InternalDashboard from "./internalDashboard/InternalDashboard";
import InternalOrganisation from "./internalOrganisation/InternalOrganisation";
import InternalWallet from "./internalWallets/InternalWallets";
import InternalFile from "./internalFile/InternalFile";
import InternalCards from "./internalcards/Internalcards";
import BulkUpload from "./bulkUpload/BulkUpload";
import InternalFileContent from "./internalFileContent/InternalFileContent";
import CardSetting from "./cardSetting/cardSetting";
import InternalTransactions from "./internalTransactions/InternalTransactions";
import Plans from "./plans/Plans";
import ChildrenPlans from "./childrenPlans/childrenPlans";
import Mcc from "src/page/mcc/Mcc";
import ReferralProgram from "./referralProgram/ReferralProgram";
import UploadFiles from "./uploadFiles/UploadFiles";
import Reports from "./reports/Reports";
import ReportsContent from "./reportsContent/ReportsContent";
// import SSOAuth from "./SSOAuthPage/SSOAuth";

const Layout = lazy(() => import("src/components/layout/Layout"));

function MainPage() {
  const menuList = useAppSelector((state) => state.auth.menuList);
  const menuItem = menuList.find((item) => item.id === "HOME" || item.id === "DASHBOARD");

  switch (menuItem?.id) {
    case "HOME":
      return <Home />;
    case "DASHBOARD":
      return <Dashboard />;
    default:
      return null;
  }
}

const router = createBrowserRouter([
  {
    path: "/sign-in",
    element: <Login />
  },
  {
    path: "/auth-sign-in",
    element: <Login />
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/wallets",
        element: <Wallet />
      },
      {
        path: "/transactions/:transactionId?",
        element: <Transactions />
      },
      {
        path: "/users/:userId",
        element: <UserDetail />
      },
      {
        path: "/wallets/:walletId",
        element: <WalletDetail />
      },
      {
        path: "/wallets/:walletId/transactions",
        element: <Transactions />
      },
      {
        path: "/users/:userId/transactions",
        element: <Transactions />
      },
      {
        path: "/cards",
        element: <Cards />
      },
      {
        path: "/wallets?/:walletId?/deposit",
        element: <Deposit />
      },
      {
        path: "/organisation",
        element: <Organisation />
      },
      {
        path: "/organisation/:organisationId",
        element: <OrganisationDetail />
      },
      {
        path: "/organisation/:organisationId/transactions",
        element: <Transactions />
      },
      {
        path: "/organisation/:organisationId/users",
        element: <Users />
      },
      {
        path: "/organisation/:organisationId/wallets",
        element: <Wallet />
      },
      {
        path: "/organisation/:organisationId/cards",
        element: <Cards />
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path: "/reports/:reportId",
        element: <ReportsContent />
      },
      {
        path: "/superLedgers",
        element: <SuperLedgers />
      },
      {
        path: "/activity",
        element: <Activity />
      },
      {
        path: "/budget",
        element: <Budget />
      },
      {
        path: "/newPayment",
        element: <NewPayment />
      },
      {
        path: "/changePin",
        element: <ChangePin />
      },
      {
        path: "/ledgers",
        element: <Ledgers />
      },
      {
        path: "/transferfunds",
        element: <InternalPayment />
      },
      {
        path: "/internal",
        element: <InternalDashboard />
      },
      {
        path: "/internal-users",
        element: <InternalUsers />
      },
      {
        path: "/internal-users/:userId",
        element: <UserDetail />
      },
      {
        path: "/internal-organisation",
        element: <InternalOrganisation />
      },
      {
        path: "/internal-wallets",
        element: <InternalWallet />
      },
      {
        path: "/internal-card",
        element: <InternalCards />
      },
      {
        path: "/internal-transactions",
        element: <InternalTransactions />
      },
      {
        path: "/internal-files",
        element: <InternalFile />
      },
      {
        path: "/internal-files/:fileId/:fileType?",
        element: <InternalFileContent />
      },
      {
        path: "/bulk-upload",
        element: <BulkUpload />
      },
      {
        path: "/cardSetting",
        element: <CardSetting />
      },
      {
        path: "/subscription",
        element: <Plans />
      },
      {
        path: "/subscription/:subscriptionId",
        element: <ChildrenPlans />
      },
      {
        path: "/mcc",
        element: <Mcc />
      },
      {
        path: "/referralProgram",
        element: <ReferralProgram />
      },
      {
        path: "/uploadFiles",
        element: <UploadFiles />
      }
    ]
  },
  {
    path: "/on-boarding",
    element: <OnBoarding />
  }
]);

export default router;
