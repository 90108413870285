import { lazy, Suspense } from "react";
import styles from "./reportsContent.module.scss";
import { reportStageMapping } from "src/components/constant/constant";
// import { reportTypeMapping } from "src/components/constant/constant";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { stageColors } from "src/variable/randomColor";
import { Link } from "react-router-dom";
import { ReportsContent } from "./reportsContentSlice";
import { dateTimeFormat, getDecimalNumber } from "src/utils/utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HoverData from "src/components/UI/hoverData/HoverData";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const DataModal = lazy(() => import("src/components/internalFileContent/dataModal/DataModal"));

const columns = [
  {
    title: "Organisation",
    dataIndex: "data",
    key: "object_id",
    render: (
      data: { organisation_name: string; organisation_id: string; organisation_type: string },
      report: ReportsContent
    ) => (
      <div>
        <div className={styles.organisationHeading}>
          <Link to={`/organisation/${report?.object_id}`}>
            {data?.organisation_id ? data?.organisation_name : report?.object_id}
          </Link>
          <HoverData
            children={
              <div>
                <CopyToClipboard text={report?.object_id}>
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}
                  >
                    {report?.object_id}
                    <ContentCopyOutlinedIcon />
                  </button>
                </CopyToClipboard>
              </div>
            }
          />
        </div>
        <div>{data?.organisation_type?.toLowerCase()}</div>
      </div>
    )
  },
  {
    title: "Stage",
    dataIndex: "data",
    key: "stage",
    render: (data: { stage: string }) => (
      <div className={styles.type}>
        <div
          className={styles.typeColor}
          style={{
            backgroundColor: `${reportStageMapping[data.stage] ? stageColors[data.stage] : ""}`,
            // borderColor: `${stageColors[data.stage]}73`,
            color: `${reportStageMapping[data.stage] ? "#ffffff" : ""}`,
            fontWeight: 600
          }}
        >
          {reportStageMapping[data?.stage] || data?.stage}
        </div>
      </div>
    )
  },
  {
    title: "Bucket Name",
    dataIndex: "data",
    key: "bucket",
    render: (data: { bucket: string }) => <div>{data.bucket ?? "---"}</div>
  },
  {
    title: "Last Transaction Detail",
    dataIndex: "data",
    key: "last_transaction_details",
    render: (data: { last_transaction_details: { date: string; amount: string } }) => (
      <div>
        {data?.last_transaction_details ? (
          <>
            <div>
              <strong>Date:</strong> {data?.last_transaction_details?.date}
              <strong>Date:</strong> {dateTimeFormat(data?.last_transaction_details?.date)?.datetime}
            </div>
            <div>
              <strong>Amount:</strong> ${getDecimalNumber(Number(data?.last_transaction_details?.amount))}
            </div>
          </>
        ) : (
          <div>---</div>
        )}
      </div>
    )
  },
  {
    title: "Last Deposit Detail",
    dataIndex: "data",
    key: "last_deposit_details",
    render: (data: { last_deposit_details: { date: string; amount: string } }) => (
      <div>
        {data?.last_deposit_details ? (
          <>
            <div>
              <strong>Date:</strong> {dateTimeFormat(data?.last_deposit_details?.date)?.datetime}
            </div>
            <div>
              <strong>Amount:</strong> ${getDecimalNumber(Number(data?.last_deposit_details?.amount))}
            </div>
          </>
        ) : (
          <div>---</div>
        )}
      </div>
    )
  },
  {
    title: "Today Transaction Detail",
    dataIndex: "data",
    key: "today_transaction_details",
    render: (data: { today_transactions_amount: string; today_transactions: string }) => (
      <div>
        <div>
          <strong>Number:</strong> {data?.today_transactions}
        </div>
        <div>
          <strong>Amount:</strong> ${getDecimalNumber(Number(data?.today_transactions_amount))}
        </div>
      </div>
    )
  },
  {
    title: "Today deposit details",
    dataIndex: "data",
    key: "today_deposit_details",
    render: (data: { today_deposit_amount: string; today_deposit: string }) => (
      <div>
        <div>
          <strong>Number:</strong> {data?.today_deposit}
        </div>
        <div>
          <strong>Amount:</strong> ${getDecimalNumber(Number(data?.today_deposit_amount))}
        </div>
      </div>
    )
  },
  {
    title: "Total Wallets Balance",
    dataIndex: "data",
    key: "total_wallets_balance",
    render: (data: { total_wallets_balance: string }) => (
      <div>${getDecimalNumber(Number(data.total_wallets_balance))}</div>
    )
  },
  {
    title: "Total Wallets",
    dataIndex: "data",
    key: "total_wallets",
    render: (data: { total_wallets: string }) => <div>{data.total_wallets}</div>
  },
  {
    title: "Total Cards",
    dataIndex: "data",
    key: "total_cards",
    render: (data: { total_cards: string }) => <div>{data.total_cards}</div>
  },
  {
    title: "Total Users",
    dataIndex: "data",
    key: "total_users",
    render: (data: { total_users: string }) => <div>{data.total_users}</div>
  },
  {
    title: "Data",
    dataIndex: "data",
    key: "data",
    render: (data: object) => {
      // const parsedData = JSON.parse(data);

      return (
        <Suspense fallback={<CircularLoader />}>
          {/* <ReportContentDataModal data={data} /> */}
          <DataModal data={data} />
        </Suspense>
      );
    }
  }
  // {
  //   title: "",
  //   dataIndex: "",
  //   key: "operation",
  //   render: (bucketName: string, data: Reports) => {
  //     return (
  //       <Link to={`/reports/${data.report_id}`} aria-label={`View file content for ${data.report_type}`}>
  //         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  //           <path
  //             d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
  //             stroke="#64748B"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           />
  //         </svg>
  //       </Link>
  //     );
  //   }
  // }
];

export default columns;
