import React from "react";
import styles from "./confirmCard.module.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

interface ConfirmCardProps {
  onClose: () => void;
  onConfirm: () => void;
  userData: {
    firstName: string;
    lastName: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    postCode: string;
  };
}

const ConfirmCard: React.FC<ConfirmCardProps> = ({ onClose, onConfirm, userData }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.referModalBox}>
        <main className={styles.referModalTop}>
          <div className={styles.close}>
            <h5>
              Confirm Card <span className={styles.highlight}>Order</span>
            </h5>
          </div>
          <p className={styles.description}>
            Please read the details of your address carefully. If you are in an office building, ensure your business
            name is correctly identified.
          </p>
          <p className={styles.infoText}>Your first card is free, but replacements have a $12 fee.</p>

          <div className={styles.addressBox}>
            <p>
              <strong>Name on Envelope:</strong> {userData.firstName} {userData.lastName}
            </p>
            <p>
              <strong>Address 1:</strong> {userData.line1}
            </p>
            <p>
              <strong>Address 2:</strong> {userData.line2 || "N/A"}
            </p>
            <p>
              <strong>Suburb:</strong> {userData.city}
            </p>
            <p>
              <strong>State:</strong> {userData.state}
            </p>
            <p>
              <strong>Postcode:</strong> {userData.postCode}
            </p>
          </div>
        </main>
        <footer className={styles.referModalBottom}>
          <Stack spacing={2} direction="row" className={styles.buttonContainer}>
            <Button onClick={onClose} variant="contained" className={styles.changeAddressButton}>
              Change Address
            </Button>
            <Button onClick={onConfirm} variant="contained" className={styles.confirmButton}>
              Confirm Order
            </Button>
          </Stack>
        </footer>
      </div>
    </div>
  );
};

export default ConfirmCard;
