import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./reportsContent.module.scss";
import Table from "rc-table";
import { FormControl, InputLabel, MenuItem, Select, TablePagination } from "@mui/material";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { useAppDispatch, useAppSelector } from "../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { LIMIT, PAGE } from "src/components/constant/queryConstants";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
import { setColumnFilter } from "../filtersSlice";
import columns from "./reportsContentConfig";
import { fetchReportsContentAction } from "./reportsContentSlice";

export default function ReportsContent() {
  const dispatch = useAppDispatch();
  const { reportId } = useParams();
  const { reportsContent, loading } = useAppSelector((state) => state.reportsContent);
  const { column: columnFilter } = useAppSelector((state) => state.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [bucket, setBucket] = useState<string[]>([]);
  const [stage, setStage] = useState<string[]>([]);
  const limit = searchParams.get(LIMIT) ?? "10";
  const page = searchParams.get(PAGE) ?? "0";

  const fetchNovattiTransaction = useCallback(() => {
    if (reportId) {
      const filter: any = {
        report_content_filter: {
          report_ids: [reportId]
        }
      };

      if (bucket.length > 0) {
        filter.report_content_filter.buckets = bucket;
      }

      if (stage.length > 0) {
        filter.report_content_filter.stages = stage;
      }
      const data = {
        size: Number(limit),
        from: Number(limit) * Number(page),
        filter
      };

      dispatch(fetchReportsContentAction(data));
    }
  }, [reportId, bucket, stage, limit, page, dispatch]);

  useEffect(() => {
    fetchNovattiTransaction();
  }, [fetchNovattiTransaction]);

  // useEffect(() => {
  //   if (reloadFileContent) {
  //     fetchNovattiTransaction();
  //     setReloadFileContent(false);
  //   }
  // }, [reloadFileContent]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(LIMIT, event.target.value as string);
    searchParams.delete(PAGE);
    setSearchParams(searchParams);
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.reportsContent?.includes(col.key));
  }, [columnFilter?.reportsContent]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(setColumnFilter({ reportsContent: selectedColumns }));
  };

  return (
    <div className={styles.fileWrapper}>
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"View Your Reports Content"} subHeading={"View details of Reports Content"} />
      </div>
      <div className={styles.fileList}>
        <div className={styles.fileListHead}>
          <div className={styles.fileListHeadIn}>
            <div className={styles.fileListHeadD}>
              <h3>Report Content List</h3>
              <p>Manage all the reports content</p>
            </div>
          </div>
          <div className={styles.fileListFilter}>
            <div className={styles.selectFilter}>
              <FormControl sx={{ minWidth: 180, maxWidth: 300 }}>
                <InputLabel id="process-status-label">Bucket Name</InputLabel>
                <Select
                  multiple
                  value={bucket || []}
                  onChange={(e) => {
                    setBucket(e.target.value as string[]);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="bucket-label"
                  label="Bucket Name"
                  sx={{
                    color: {
                      COMPLETED: "",
                      FAILED: "",
                      CREATED: "",
                      "": "grey",
                      default: "grey"
                    }[bucket.length > 0 ? bucket[0] : "default"]
                  }}
                >
                  <MenuItem value="ORGANISATION_REGISTERED">Organisation Registered</MenuItem>
                  <MenuItem value="SUBSCRIPTION_SELECTED">Subscription Selected</MenuItem>
                  <MenuItem value="USERS_CREATED">Users Created</MenuItem>
                  <MenuItem value="WALLETS_CREATED">Wallets Created</MenuItem>
                  <MenuItem value="PRE_TRANSACTION">Pre Transaction</MenuItem>
                  <MenuItem value="TRANSACTED">Transacted</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 180, maxWidth: 300 }}>
                <InputLabel id="settlement-status-label">Stage</InputLabel>
                <Select
                  multiple
                  value={stage}
                  onChange={(e) => {
                    setStage(e.target.value as string[]);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="stage-label"
                  label="Stage"
                  sx={{
                    color: {
                      MATCHED: "",
                      MISMATCHED: "",
                      NOVATTI_ONLY: "",
                      "": "grey",
                      default: "grey"
                    }[bucket.length > 0 ? bucket[0] : "default"]
                  }}
                >
                  <MenuItem value="STAGE_1">Stage 1</MenuItem>
                  <MenuItem value="STAGE_2">Stage 2</MenuItem>
                  <MenuItem value="STAGE_3">Stage 3</MenuItem>
                  <MenuItem value="STAGE_4">Stage 4</MenuItem>
                  <MenuItem value="STAGE_5">Stage 5</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TableColumnFilter
              columns={columns}
              selectedColumn={columnFilter?.reportsContent}
              mandatoryColumns={["object_id", "data"]}
              onApply={handleColumnSelection}
              iconContanerClass={styles.tableColumnContainer}
            />
          </div>
        </div>
        <div className={styles.fileListTable}>
          <Table
            columns={filteredColumns}
            data={loading ? [] : reportsContent.list}
            className={styles.tableIn}
            rowKey="id"
            components={
              loading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={reportsContent.size ?? 0}
            page={parseInt(page)}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(limit)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}
