import client from "../client";
import { PaginationRequest } from "src/types/commonTypes";

export interface ReportsRequest extends PaginationRequest {
  filter?: {
    report_filter?: {
      report_date?: {
        min?: string;
        max?: string;
      };
    };
  };
}

export const getReport = (data: ReportsRequest) => {
  return client({
    query: `query SearchReport($input: SearchInput) {
        search_report(input: $input) {
            total_pages                
            total_size                
            reports {                    
                report_id             
                report_type  
                status    
                report_date                        
            }    
        }
    }`,
    variables: {
      input: data
    }
  });
};

export interface ReportsContentRequest extends PaginationRequest {
  filter: {
    report_content_filter: {
      report_ids: string;
      buckets?: string[];
      stages?: string[];
    };
  };
}

export const getReportContent = (data: ReportsContentRequest) => {
  return client({
    query: `query SearchReportContent($input: SearchInput) {
        search_report_content(input: $input) {
            total_pages                
            total_size                
            report_content_list {                    
                report_id             
                report_content_id             
                report_type  
                object_id
                object_type
                data                          
            }    
        }
    }`,
    variables: {
      input: data
    }
  });
};
