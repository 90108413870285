import { dateTimeFormat } from "src/utils/utils";
import { Link } from "react-router-dom";
import { Reports } from "./reportsSlice";
import { reportTypeMapping } from "src/components/constant/constant";

const columns = [
  {
    title: "Id",
    dataIndex: "report_id",
    key: "report_id"
  },
  {
    title: "Date",
    dataIndex: "report_date",
    key: "report_date",
    render: (report_date: string) => <div>{dateTimeFormat(report_date).date}</div>
  },
  {
    title: "Type",
    dataIndex: "report_type",
    key: "report_type",
    render: (report_type: string) => <div>{reportTypeMapping[report_type] || report_type}</div>
  },
  {
    title: "Process Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "",
    dataIndex: "",
    key: "operation",
    render: (bucketName: string, data: Reports) => {
      return (
        <Link to={`/reports/${data.report_id}`} aria-label={`View file content for ${data.report_type}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      );
    }
  }
];

export default columns;
