import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import styles from "./childrenPlans.module.scss";
import Table from "rc-table";
import { Button, TablePagination } from "@mui/material";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import { useAppDispatch, useAppSelector } from "../store";
import columns from "./childrenPlansConfig";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { LIMIT, PAGE } from "src/components/constant/queryConstants";
import Modalui from "src/components/UI/modal/Modalui";
import { setColumnFilter } from "../filtersSlice";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";
import AddIcon from "@mui/icons-material/Add";
import { createSubscriptionAction, fetchListSubscription, getSubscriptionAction } from "./childrenPlansSlice";
import OnBoardingSuccess from "src/components/onBoardingComponent/OnBoardingSuccessCard";
import Success from "src/assets/images/success1.png";
import { SubsRequest } from "src/network/graphql/subscriptionServices";
import PlanPreviewModal from "src/components/subscriptionPlan/planPreviewModal/PlanPreviewModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OrganizationType } from "src/types/commonTypes";
import { SubsList } from "../plans/plansSlice";
const CreatePlan = lazy(() => import("src/components/subscriptionPlan/createPlan/CreatePlan"));
const SubscriptionPreference = lazy(
  () => import("src/components/subscriptionPlan/subscriptionPreference/subscriptionPreference")
);
const OperationColumn = lazy(() => import("src/components/subscriptionPlan/operationColumn/OperationColumn"));

export default function ChildrenPlans() {
  const dispatch = useAppDispatch();
  const { subscriptionId } = useParams();
  const { subscriptionList, loading, recentCreatedPlanSubId, createSubLoading, detail } = useAppSelector(
    (state) => state.childrenPlans
  );
  const { column: columnFilter } = useAppSelector((state) => state.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCreateSubscription, setOpenCreateSubscription] = useState<boolean>(false);
  const [openPlanPreference, setOpenPlanPreference] = useState<boolean>(false);
  const [createSubscriptionSuccess, setCreateSubscriptionSuccess] = useState<boolean>(false);
  const limit = searchParams.get(LIMIT) ?? "10";
  const page = searchParams.get(PAGE) ?? "0";

  const fetchSubscriptionList = useCallback(() => {
    const data = {
      limit: Number(limit),
      page: Number(page),
      subscription_filter: {
        parent_subscription_id: subscriptionId
      }
    };

    dispatch(fetchListSubscription(data));
  }, [dispatch, page, limit, subscriptionId]);

  useEffect(() => {
    fetchSubscriptionList();
  }, [subscriptionId]);

  useEffect(() => {
    if (subscriptionId) {
      dispatch(getSubscriptionAction(subscriptionId));
    }
  }, [subscriptionId]);

  const handleCreatePlan = (data: SubsRequest) => {
    dispatch(
      createSubscriptionAction({ ...data, parent_subscription_id: subscriptionId }, () => {
        setOpenCreateSubscription(false);
        fetchSubscriptionList();
        setCreateSubscriptionSuccess(true);
      })
    );
  };

  const handleOpenPlanPreference = () => {
    setCreateSubscriptionSuccess(false);
    setOpenPlanPreference(true);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(LIMIT, event.target.value as string);
    searchParams.delete(PAGE);
    setSearchParams(searchParams);
  };

  const operation = {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (text: any, card: SubsList) => (
      <OperationColumn onUpdate={fetchSubscriptionList} data={card} parentData={detail} />
    )
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.childrenPlans?.includes(col.key));
  }, [columnFilter?.childrenPlans]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        childrenPlans: selectedColumns
      })
    );
  };

  const updatedColumns = [...filteredColumns, operation];

  return (
    <>
      <Modalui open={openCreateSubscription} handleClose={() => setOpenCreateSubscription(false)}>
        <Suspense fallback={<CircularLoader />}>
          <CreatePlan
            handleClose={() => setOpenCreateSubscription(false)}
            loading={createSubLoading}
            onSubmit={handleCreatePlan}
            organizationType={detail?.orgType as OrganizationType}
          />
        </Suspense>
      </Modalui>
      <Modalui open={openPlanPreference} handleClose={() => setOpenPlanPreference(false)}>
        <Suspense fallback={<CircularLoader />}>
          <SubscriptionPreference
            handleClose={() => setOpenPlanPreference(false)}
            onSuccess={() => {}}
            subscriptionId={recentCreatedPlanSubId}
          />
        </Suspense>
      </Modalui>
      <Modalui
        open={createSubscriptionSuccess}
        handleClose={() => {
          setCreateSubscriptionSuccess(false);
        }}
      >
        <OnBoardingSuccess
          onNext={handleOpenPlanPreference}
          onBack={() => setCreateSubscriptionSuccess(false)}
          heading="Hurray! Your Subscription Plan has been created."
          subHeading="Just few Steps left for limit preference, permission preference and menu preference to your Subscription Plan."
          buttonText={{ primaryButton: "Sure", secondaryButton: "I'll do it later" }}
          image={Success}
        />
      </Modalui>
      <div className={styles.wrapper}>
        <div className={styles.WelCard}>
          <TransactionWelCard
            heading={`View ${detail.title}'s Plans`}
            subHeading={`View details of ${detail.title}'s plans`}
          />
        </div>
        <div className={styles.list}>
          <div className={styles.listHead}>
            <div className={styles.listHeadIn}>
              <div className={styles.listHeadD}>
                <h3 className={styles.title}>
                  {`${detail.title}'s Plans list`}
                  <PlanPreviewModal
                    title={detail.title}
                    subTitle={detail.subTitle}
                    description={detail.description}
                    category={detail.subscription_category}
                    icon={<InfoOutlinedIcon fontSize="small" />}
                  />
                </h3>
                <p>Manage all the plans</p>
              </div>
              <div className={styles.listHeadBtn}>
                <Button
                  variant="contained"
                  className={styles.createPlan}
                  onClick={() => setOpenCreateSubscription(true)}
                >
                  <AddIcon fontSize="small" />
                  Create Plan
                </Button>
              </div>
            </div>
            <div className={styles.cardListFilter}>
              <TableColumnFilter
                columns={columns}
                selectedColumn={columnFilter?.childrenPlans}
                mandatoryColumns={["subsId"]}
                onApply={handleColumnSelection}
                iconContanerClass={styles.tableColumnContainer}
              />
            </div>
          </div>
          <div className={styles.listTable}>
            <Table
              columns={updatedColumns}
              data={loading ? [] : subscriptionList.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={subscriptionList.size ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(limit)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
