export const SUPPORTER = "SUPPORTER";
export const MANAGER = "MANAGER";
export const PARTICIPANT = "PARTICIPANT";
export const GUARDIAN = "GUARDIAN";
export const INTERNAL_ADMIN = "INTERNAL_ADMIN";
export const SUPER_ADMIN = "SUPER ADMIN";
export const OPERATIONS_MANAGER = "ORGANISATION_OPERATIONS_MANAGER";
export const FINANCE_MANAGER = "ORGANISATION_FINANCE_MANAGER";
export const NOVATTI_DAILY_TRANSACTION = "NOVATTI_DAILY_TRANSACTION";
export const SHARED = "SHARED";
export const PRIVATE = "PRIVATE";
export const CAN_ADD_USER = "CAN_ADD_USER";
export const CAN_ADD_CARD = "CAN_ADD_CARD";
export const CAN_UPDATE_CARD = "CAN_UPDATE_CARD";
export const CAN_CHANGE_CARD_PIN = "CAN_CHANGE_CARD_PIN";
export const CAN_EDIT_USER_INFORMATION = "CAN_EDIT_USER_INFORMATION";
export const CAN_EDIT_TRANSACTION = "CAN_EDIT_TRANSACTION";
export const CAN_UPDATE_WALLET_SETTINGS = "CAN_UPDATE_WALLET_SETTINGS";
export const CAN_ADD_ORGANSIATION = "CAN_ADD_ORGANSIATION";
export const CAN_UPDATE_BUDGET = "CAN_UPDATE_BUDGET";
export const CAN_UPDATE_REPEAT_PAY = "CAN_UPDATE_REPEAT_PAY";
export const CAN_CONNECT_USER_TO_WALLET = "CAN_CONNECT_USER_TO_WALLET";
export const CAN_CONNECT_CARD_TO_WALLET = "CAN_CONNECT_CARD_TO_WALLET";
export const CAN_CONNECT_CARD = "CAN_CONNECT_CARD";
export const CAN_CONNECT_CARD_TO_ORGANISATION = "CAN_CONNECT_CARD_TO_ORGANISATION";
export const CAN_CONNECT_WALLET_TO_ORGANISATION = "CAN_CONNECT_WALLET_TO_ORGANISATION";
export const CAN_CONNECT_USER_TO_ORGANISATION = "CAN_CONNECT_USER_TO_ORGANISATION";
export const CAN_UPDATE_ORGANISATION = "CAN_UPDATE_ORGANSIATION";
export const CAN_CHANGE_PIN = "CAN_CHANGE_PIN";
export const CAN_UPDATE_USER_ROLE = "CAN_UPDATE_USER_ROLE";
export const CAN_UPDATE_USER_KYC = "CAN_UPDATE_USER_KYC";
export const CAN_CONNECT_WALLET_TO_USER = "CAN_CONNECT_WALLET_TO_USER";
export const CAN_RESET_PASSWORD = "CAN_RESET_PASSWORD";
export const CAN_ADD_WALLET = "CAN_ADD_WALLET";
export const CAN_UPDATE_EMAIL = "CAN_UPDATE_EMAIL";
export const CAN_TOGGLE_CARD_STATUS = "CAN_TOGGLE_CARD_STATUS";
export const CAN_ACT_ON_QUICK_LINKS = "CAN_ACT_ON_QUICK_LINKS";
export const CAN_ACCESS_ONBOARDING = "CAN_ACCESS_ONBOARDING";
export const CAN_DEPOSIT = "CAN_DEPOSIT";
export const CAN_DEPOSIT_TO_CASH_WALLET = "CAN_DEPOSIT_TO_CASH_WALLET";
export const CAN_REPORT_CASH_TRANSACTION = "CAN_REPORT_CASH_TRANSACTION";
export const CAN_UPGRADE_CARD = "CAN_UPGRADE_CARD";
export const CAN_TRANSFER_BETWEEN_WALLET = "CAN_TRANSFER_BETWEEN_WALLET";
export const CAN_LOAD_CARD = "CAN_LOAD_CARD";
export const CAN_VIEW_SUBSCRIPTION_PLAN = "CAN_VIEW_SUBSCRIPTION_PLAN";
export const CAN_ACCESS_ORDER_CARD_BUTTON = "CAN_ACCESS_ORDER_CARD_BUTTON";
export const CAN_CREATE_GENERAL_WALLET = "CAN_CREATE_GENERAL_WALLET";
export const CAN_CREATE_CASH_WALLET = "CAN_CREATE_CASH_WALLET";
export const CAN_SEE_HOW_TO_DEPOSIT = "CAN_SEE_HOW_TO_DEPOSIT";
export const CAN_SEE_CARD_ON_USER_DETAIL = "CAN_SEE_CARD_ON_USER_DETAIL";
export const CAN_BLOCK_MERCHANT = "CAN_BLOCK_MERCHANT";
export const EMPTY_STRING = "";

export const planCategoryMapping: Record<string, string> = {
  BASE: "Base",
  TOP_UP: "Top Up"
};

export const roleMapping: Record<string, string> = {
  ORGANISATION_SUPER_ADMIN: "Super Admin",
  GUARDIAN: "Guardian",
  SUPPORTER: "Supporter",
  PARTICIPANT_SELF_MANAGED: "Participant (Self Managed)",
  ORGANISATION_FINANCE_MANAGER: "Finance Manager",
  ORGANISATION_OPERATIONS_MANAGER: "Operations Manager",
  PARTICIPANT: "Participant",
  INTERNAL_CUSTOMER_SUPPORT: "Internal Customer Supporter",
  INTERNAL_FINANCE: "Internal Finance",
  INTERNAL_ADMIN: "Internal Admin",
  NOROLE: ""
};

export const activityNameMapping: Record<string, string> = {
  NOVATTI_CARD_TRANSACTION: "Novatti Transaction",
  NOVATTI_DAILY_TRANSACTION: "Novatti Daily Transaction",
  WALLET_TRANSACTION: "Wallet Transaction",
  CASH_TRANSACTION: "Cash Transaction",
  DEPOSIT: "Deposit",
  CREATE_USER: "Create User"
};

export const reportTypeMapping: Record<string, string> = {
  ORGANISATION_DAILY_EVALUATION: "Organisation Daily Evaluaiton"
};

export const reportStageMapping: Record<string, string> = {
  STAGE_1: "Stage 1",
  STAGE_2: "Stage 2",
  STAGE_3: "Stage 3",
  STAGE_4: "Stage 4",
  STAGE_5: "Stage 5"
};

export const walletTypeToActivityName: Record<string, string> = {
  GENERAL: "NOVATTI_CARD_TRANSACTION",
  SPENDING: "NOVATTI_CARD_TRANSACTION",
  DIGITAL: "NOVATTI_CARD_TRANSACTION",
  CASH: "CASH_TRANSACTION"
};

export const walletTypeMapping: Record<string, string> = {
  GENERAL: "General",
  SPENDING: "Spending",
  DIGITAL: "Digital",
  CASH: "Cash"
};

export const rolesCanBeAdded = [
  "ORGANISATION_SUPER_ADMIN",
  "SUPPORTER",
  "ORGANISATION_FINANCE_MANAGER",
  "ORGANISATION_OPERATIONS_MANAGER",
  "PARTICIPANT",
  "INTERNAL_ADMIN"
];
export const reverseRoleMapping: Record<string, string> = {
  ["Super Admin"]: "ORGANISATION_SUPER_ADMIN",
  ["Operations Manager"]: "ORGANISATION_OPERATIONS_MANAGER",
  ["Finance Manager"]: "ORGANISATION_FINANCE_MANAGER",
  ["Guardian"]: "GUARDIAN",
  ["Supporter"]: "SUPPORTER",
  ["Participant"]: "PARTICIPANT",
  ["Participant (Self Managed)"]: "PARTICIPANT_SELF_MANAGED",
  ["Internal Admin"]: "INTERNAL_ADMIN",
  ["Internal Customer Supporter"]: "INTERNAL_CUSTOMER_SUPPORT",
  ["Internal Finance"]: "INTERNAL_FINANCE"
};
